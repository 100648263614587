
import { reactive } from "vue";

import { EventEmitter, userSession } from 'o365-modules';

import Distinct from "./controls.filter.fields.Distinct.js";
import Checkbox from "./controls.filter.fields.Checkbox.js";
import FieldFilters from "./controls.filter.fields.Filters.ts";
import Range from "./controls.filter.fields.Range.js";
import Slider from "./controls.filter.fields.Slider.js";
import Filters from "./controls.filter.fields.Filters.ts";

const fieldTypes = {
    "Distinct": Distinct,
    "Checkbox": Checkbox,
    "Range": Range,
    "Slider": Slider,
    "Filters": Filters,
};

const filters = {};

export default class Filter {
    constructor(options) {
        Object.assign(this, options);

        this.storageKey = `MobileFilter:${this.id}:` + (options.registerID ?? 'default');
        this.loadStorage();

        this.search = "";

        this.field = null;
        this.order = null;
        this.savedFilter = null;

        this.showFieldSheet = false;
        this.showOrderSheet = false;

        this.eventHandler = new EventEmitter();
        this.isSavedFiltersShown = false;

        filters[this.id] = this;
    }

    loadStorage() {
        try { this.storage = JSON.parse(localStorage.getItem(this.storageKey)) } catch (e) {}

        // validate storage
        if (this.storage == null || typeof this.storage !== "object") {
            this.storage = {};
        }
        if (this.storage.fields == null || typeof this.storage.fields !== "object") {
            this.storage.fields = {};
        }
        if (!Array.isArray(this.storage.sorts)) {
            this.storage.sorts = [];
        }
        if (!this.orders[this.storage.order]) {
            this.storage.order = 0;
        }

        // create filter fields
        if (this.filters) {
            for (let [index, filter] of Object.entries(this.filters.toReversed())) {
                let clause = filter.Filter;
                if (clause) {
                    clause = clause.replace("@Omega365Person_ID", userSession.personId);
                }
                const options = {
                    filter: this,
                    field: "c_" + filter.Name,
                    type: "Checkbox",
                    name: filter.Title,
                    checkedClause: clause,
                    group: "filters",
                    group_first: index == 0, // string vs number compare
                    group_last: index == (this.filters.length - 1), // string vs number compare
                };
                this.fields.push(options);
                // find out how to add default
            }
        }

        // create fields
        this.fields = this.fields.map(e => new fieldTypes[e.type]({ ...e, filter: this }));

        ///{ type: "Checkbox", field: "c_CreatedByMe", name: $t("Created by me"), checkedClause: "exists_clause(aviw_Scope_ItemsCreatedBy, T2.ID = T1.ID, T2.CreatedBy_ID = " + userSession.personId + ")" },

        // get order
        this.order = this.orders[this.storage.order];

        this.saveStorage();
    }

    saveStorage() {
        localStorage.setItem(this.storageKey, JSON.stringify(this.storage));
    }

    selectField(field) {
        this.field = field;
        if (field) {
            this.showFieldSheet = true;
        }
    }
    
    selectOrder(order) {
        this.storage.order = order;
        this.saveStorage();
    }

    // save saved Filter to the local storage
    async selectSavedFilter(filter) {
        this.savedFilter = filter;

        this.removeDistinctFilters();

        // applying saved filter. next 6 lines have taken from o365-filterobject.FilterObject.ts        
        this.dataObject.filterObject.activeFilter = filter;
        await this.dataObject.filterObject.applyInitFilter(filter.FilterCriteria, true);

        const filterFields = (Object.values(this.dataObject.filterObject.filterItems))
            .filter(item => item.selectedValue)
            .map((item) => item.column);

        this.dataObject.filterObject.setFieldFilters(filterFields, true, true);
    
        // save choosen filter to local storage.
        if (filter.PrimKey !== this.storage?.savedFilter?.PrimKey) {
            this.storage.savedFilter = filter;
            this.saveStorage();
        }
    }

    removeSavedFilter() {
        this.savedFilter = null;
        this.storage.savedFilter = null;
        this.saveStorage();
    }

    clearField() {
        if (this.field) {
            this.field.clear();
        }
        this.selectField(null);
        this.showFieldSheet = false;
    }


    getSortOrder() {
        return this.orders[this.storage.order]?.order || [];
    }

    getSearchClause() {
        if (!this.search) {
            return;
        }

        let clauses = [];

        for (let name of this.searchFields) {
            clauses.push(`[${name}] LIKE '%${this.search}%'`);
        }

        return clauses.map(e => `(${e})`).join(" OR ");
    }

    getFilterItems() {
        let items = [];
        
        // if (this.storage.savedFilter) {
        //     const mergedColumns = await filterStringToFilterItems(this.storage.savedFilter.savedFilter?.FilterCriteria);
        //     items = items.concat(mergedColumns)
        // }

        for (let field of this.fields) {
            const newItems = field.getFilterItems();

            if (newItems) {
                items = items.concat(newItems);
            }
        }
        
        return items;
    }

    getFilterItemsSearchClause() {
        const clauses = [];

        let searchValues;
        if (this.exactSearch) {
            searchValues = [ this.search ];
        } else {
            searchValues = this.search.trim().split(/\s+/g).filter((searchValue) => searchValue);
        }

        for (const name of this.searchFields) {
            for (const searchValue of searchValues) {
                clauses.push(`[${name}] LIKE '%${searchValue}%'`);
            }
        }

        if (clauses.length) {
            return clauses.map(e => `(${e})`).join(" OR ");
        }
    }

    getFilterItemsWhereClause() {
        const clauses = [];
        // const savedFilter =  this.storage?.savedFilter;

        for (let field of this.fields) {
            // field.getFilterItemsWhereClause
            // getFilterItemsWhereClause exists in Checkbox and FieldFilters types.
            if (field.getFilterItemsWhereClause) {
                const clause = field.getFilterItemsWhereClause();
                if (clause) {
                    clauses.push(clause);
                }
            }
        }

        const searchClause = this.getFilterItemsSearchClause();
        if (searchClause) {
            clauses.push(searchClause);
        }

        // if (savedFilter) {
        //     if (clauses.length) {
        //         clauses.push(savedFilter?.FilterCriteria);
        //     }
        // }

        if (!clauses.length) {
            return null;
        }

        return clauses.map(e => `(${e})`).join(" AND ");
    }

    removeDistinctFilters() {
        // removing filters from the object 
        for (let field of this.fields) {
            if (field.storage.values2) {
                field.storage.values2 = [];
            }
        }
        
        for (const property in this.storage.fields) {
            if (this.storage.fields[property]) {
                this.storage.fields[property].values2 = [];
            }
        }

        this.saveStorage();
    }

    async applyToFilterObject(filterObject) {
        filterObject ??= this.dataObject?.filterObject;

        if (filterObject === undefined || filterObject === null) {
            console.warn('No filter object or data object provided');
            return;
        }

        const items = this.getFilterItems();

        filterObject.clear();
        filterObject.updateItems({ items: items });
        
        filterObject.apply();
    }

    getFilterItemsFilterString() {
        const items = this.getFilterItems();
        this.dataObject.filterObject.clear();
        this.dataObject.filterObject.updateItems();
    }

    getFilterString(exceptField) {
        let clauses = [];

        for (let field of this.fields) {
            if (field !== exceptField) {
                const clause = field.getFilterString();
                if (clause) {
                    clauses.push(clause);
                }
            }
        }

        const searchClause = this.getSearchClause();
        if (searchClause) {
            clauses.push(searchClause);
        }

        return clauses.map(e => `(${e})`).join(" AND ");
    }

    update() {
        this.saveStorage();
        this.eventHandler.emit("update");
    }

    on(event, listener) {
        return this.eventHandler.on(event, listener);
    }

    off(event, listener) {
        return this.eventHandler.off(event, listener);
    }
}

export function getOrCreateFilter(options) {
    if (!filters[options.id]) {
        new Filter(options);
    }
    return reactive(filters[options.id]);
}
